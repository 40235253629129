<template>
  <path id="path4034" d="M1128.118-697.559a18.564,18.564,0,0,0-17.238-11.712,18.564,18.564,0,0,0-17.238,11.712,15.329,15.329,0,0,0-14.763,15.3,15.328,15.328,0,0,0,15.311,15.312h2.728l0-.041h2.03a15.326,15.326,0,0,0,9.659-3.439,15.4,15.4,0,0,0,5.1-7.787h2.161l.049,0a1.763,1.763,0,0,0,1.633-1.156,1.816,1.816,0,0,0-.429-1.811l-4.919-5.662-.043-.048a1.454,1.454,0,0,0-1.028-.422,1.453,1.453,0,0,0-1.039.432l-.024.022-5.056,5.692a1.772,1.772,0,0,0-.424,1.884,1.74,1.74,0,0,0,1.709,1.074h1.884a10.149,10.149,0,0,1-9.23,6.012H1093.3a10.093,10.093,0,0,1-9.207-10.059,10.109,10.109,0,0,1,10.1-10.1h3.315l.526-1.917a13.353,13.353,0,0,1,12.848-9.784,13.352,13.352,0,0,1,12.847,9.784l.527,1.917h3.314a10.11,10.11,0,0,1,10.1,10.1,10.11,10.11,0,0,1-10.1,10.1h-3.952l0,.036h-.846a15.313,15.313,0,0,0-9.871,3.614,15.387,15.387,0,0,0-5.02,8.132h-2.327l-.049.006a1.763,1.763,0,0,0-1.633,1.156,1.815,1.815,0,0,0,.429,1.811l4.917,5.664.045.045a1.449,1.449,0,0,0,1.028.424,1.45,1.45,0,0,0,1.038-.432l.024-.024,5.056-5.689a1.776,1.776,0,0,0,.425-1.886,1.741,1.741,0,0,0-1.711-1.073h-1.8a10.046,10.046,0,0,1,9.447-6.532l6.527-.135a15.231,15.231,0,0,0,9.28-4.586,15.225,15.225,0,0,0,4.3-10.627,15.328,15.328,0,0,0-14.761-15.3Z" transform="translate(-1078.379 709.771)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
</template>

<script>
export default {
name: "IconSave"
}
</script>

<style scoped>

</style>